<template>
    <div class="edit-profile-detail">
        <div class="title" v-html="`${$translate(familyType.name.toUpperCase())}`" />
        <div class="sibling-accordion">
            <div v-for="i in siblings" :key="i" class="sibling-accordion-item-wrapper">
                <div v-if="i !== profile.order_of_children" class="sibling-accordion-item">
                    <div class="item-title" @click="onClickAccordionItem(i - 1)">
                        <div class="item-label">
                            {{ siblingOrderLabel[i - 1] }}
                        </div>
                        <img
                            :src="require(`@/assets/images/icons/${arrows[i - 1] ? 'arrow_open' : 'arrow_closed'}.png`)"
                            width="24px"
                            height="24px"
                        />
                    </div>
                    <div class="sibling-content" v-if="arrows[i - 1]">
                        <div class="content-section">
                            <div class="section-title required" v-html="`형제 관계`" />
                            <TextareaWithX
                                v-model="brotherhoodStatus[i - 1]"
                                :placeholder="`첫째 누나,셋째 남동생 등`"
                                :isInputMode="true"
                            />
                        </div>
                        <div class="content-section">
                            <div class="section-title required" v-html="`최종학력`" />
                            <SelectInput
                                :options="educationStatus"
                                :alreadySelected="(selectedEduStatus[i - 1] || {}).id"
                                @selected="item => onSelectEduStatus(i - 1, item)"
                                :scrollFix="true"
                            />
                        </div>
                        <div class="content-section" v-if="selectedEduStatus[i - 1] && selectedEduStatus[i - 1].id > 2">
                            <div class="section-title" v-html="`출신 대학교`" />
                            <div class="search-box-wrapper" @click="onClickUniv(i - 1)">
                                <div class="search-box" v-if="universities[i - 1]" v-html="universities[i - 1].name" />
                                <div class="search-box" v-else>
                                    <i class="material-icons">search</i>
                                    <span v-html="$translate(`PLACEHOLDER_SEARCH_EDUCATION_UNIVERSITY`)" />
                                </div>
                            </div>
                        </div>
                        <div class="content-section">
                            <div class="section-title required" v-html="`직업`" />
                            <TextareaWithX v-model="jobnames[i - 1]" placeholder="임원, 주부 등" :isInputMode="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectInput from '@/components/app/SelectInput'

export default {
    name: 'Sibling',
    props: {
        familyType: Object,
    },
    components: {
        SelectInput,
    },

    data: () => ({
        arrows: [],
        brotherhoodStatus: [],
        selectedEduStatus: [],
        universities: [],
        jobnames: [],
        editingSibling: null,
    }),
    mounted() {
        this.arrows = new Array(this.siblings).fill(false)
        this.selectedEduStatus = new Array(this.siblings).fill(false)
        this.universities = new Array(this.siblings).fill(false)
        this.brotherhoodStatus = new Array(this.siblings).fill(null)
        this.jobnames = new Array(this.siblings).fill(null)

        this.init()
    },
    watch: {
        disabled: function () {
            this.$emit('disabled', this.disabled)
        },
    },
    computed: {
        educationStatus() {
            return Object.keys(this.$profile.educationStatus).map(key => this.$profile.educationStatus[key])
        },
        disabled() {
            if (!(this.brotherhoodStatus.length && this.selectedEduStatus.length && this.jobnames.length)) return true
            const everyBrotherhoodStatus = this.brotherhoodStatus.every((s, i) => {
                if (i + 1 === this.profile.order_of_children) return true
                else return s
            })
            const everySelectedEduStatus = this.selectedEduStatus.every((s, i) => {
                if (i + 1 === this.profile.order_of_children) return true
                else return s
            })
            const everyJobnames = this.jobnames.every((s, i) => {
                if (i + 1 === this.profile.order_of_children) return true
                else return s
            })
            return !(everyBrotherhoodStatus && everySelectedEduStatus && everyJobnames)
        },
        profile() {
            return this.$store.getters.profile || []
        },
        siblingOrderLabel() {
            return [
                '첫째',
                '둘째',
                '셋째',
                '넷째',
                '다섯째',
                '여섯째',
                '일곱째',
                '여덟째',
                '아홉째',
                '열째',
                '열한째',
                '열째',
                '열셋째',
                '열넷째',
            ]
        },
        siblings() {
            return this.profile.brother_count + this.profile.sister_count
        },
    },
    methods: {
        onClickAccordionItem(idx) {
            // 아코디언 열리는 거 조절
            const temp = this.arrows.map((arrow, i) => {
                if (i === idx) {
                    return !arrow
                } else {
                    return arrow
                }
            })
            this.arrows = [...temp]
        },
        onSelectEduStatus(idx, item) {
            // 학력상태 값 저장
            const temp = this.selectedEduStatus.map((status, i) => {
                if (i === idx) {
                    return item
                } else {
                    return status
                }
            })
            this.selectedEduStatus = [...temp]
        },
        async onClickUniv(idx) {
            // 대학교 저장
            const university = await this.$modal.custom({
                component: 'ModalProfileItemSelector',
                options: {
                    model: 'university',
                },
            })
            const temp = this.universities.map((uni, i) => {
                if (i === idx) {
                    return university
                } else {
                    return uni
                }
            })
            this.universities = [...temp]
        },
        init() {
            if (this.profile.family.length) {
                this.profile.family.forEach(f => {
                    if (f.family_type === this.familyType.id && f.sibling_order) {
                        const idx = f.sibling_order - 1
                        this.jobnames[idx] = f.job_name
                        this.brotherhoodStatus[idx] = f.brotherhood_status
                        this.selectedEduStatus[idx] = this.educationStatus[f.education_status]
                        if (f.university_id) {
                            this.universities[idx] = {
                                id: f.university_id,
                                name: f.university_name,
                            }
                        }
                    }
                })
            }
        },

        onSave(key) {
            try {
                const { family } = this.$store.getters.profile

                // flatmap 사용으로 변경
                const siblings = new Array(this.siblings).fill(false).flatMap((s, i) => {
                    if (i + 1 !== this.profile.order_of_children) {
                        // 형제 순서에서 내가 아닐경우에만 가족에 저장한다.
                        const sibling = {}
                        sibling.family_type = this.familyType.id
                        sibling.job_name = this.jobnames[i]
                        sibling.job_status = 1
                        sibling.brotherhood_status = this.brotherhoodStatus[i]
                        sibling.education_status = this.selectedEduStatus[i].id
                        sibling.sibling_order = i + 1
                        if ((this.selectedEduStatus[i] || {}).id >= 2 && this.universities[i]) {
                            sibling.university_id = this.universities[i].id
                            sibling.university_name = this.universities[i].name
                        }
                        return [sibling]
                    } else {
                        return []
                    }
                })

                let updatedFamily = []

                updatedFamily.push(...family.filter(f => f.family_type === 1 || f.family_type === 0))
                updatedFamily = [...updatedFamily, ...siblings]

                this.$set(this.$store.getters.profile, 'family', updatedFamily)
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.sibling-accordion {
    padding: 0 16px;
    .sibling-accordion-item {
        border-bottom: 1px solid #e9e9ed;
        .item-title {
            padding: 8px 0;
            width: 100%;
            height: 58px;
            background-color: transparent;

            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #111111;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        .item-icon {
            display: flex;
            align-content: center;
        }

        .sibling-content {
            margin-bottom: 16px;
            .content-section {
                padding: 8px;
                background: #f7f8f9;
                .section-title {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #111111;
                    margin-bottom: 8px;
                }
                &:not(:last-child) {
                    padding-bottom: 24px;
                }

                .search-box-wrapper {
                    background-color: #fff;
                    padding: 16px;
                    border: 1px solid #e9e9ed;
                    border-radius: 8px;
                    height: 48px;
                    display: flex;
                    align-items: center;

                    .search-box {
                        display: flex;
                        align-items: center;
                        color: #ced5d9;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
    .required::after {
        content: ' *';
        color: $blue-primary;
    }
}
</style>
